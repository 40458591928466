import * as fonts from './fonts'
import * as breakpoints from './breakpoints'
// import { vw } from './vw'

export const fluidTypography = (minFontSize, maxFontSize, minViewPortSize, maxViewPortSize) => {
  return `calc(${minFontSize}px + (${maxFontSize - minFontSize} * ((100vw - ${minViewPortSize}px) / (${maxViewPortSize - minViewPortSize}))))`
}

export const getFontScaling = (minFontSize, scaleToXl = 1.12, scaleToXxxl = 1.5) => ({
  [breakpoints.up('lg')]: {
    fontSize: minFontSize
  }
  // [breakpoints.up('lg')]: {
  //   fontSize: fluidTypography(minFontSize, minFontSize * scaleToXl, breakpoints.values.lg, breakpoints.values.xl)
  // },
  // [breakpoints.up('xl')]: {
  //   fontSize: fluidTypography(minFontSize * scaleToXl, minFontSize * scaleToXxxl, breakpoints.values.xl, breakpoints.values.xxxl)
  // }
})

export const fontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900
}

export const baseHeadingsStyles = {
  fontFamily: fonts.body,
  fontWeight: fontWeight.regular,
  lineHeight: 0.8
}

export const baseBodyStyles = {
  fontFamily: fonts.body,
  fontWeight: fonts.bodyFontWeight,
  fontSize: 14,
  lineHeight: 1.4
}

export const baseMonoStyles = {
  fontFamily: fonts.mono,
  fontWeight: fonts.monoFontWeight
}

export const h0 = {
  extend: baseHeadingsStyles,
  fontSize: 48,
  lineHeight: 1,
  letterSpacing: '-0.01em',
  textTransform: 'uppercase',
  [breakpoints.up('md')]: {
    fontSize: 80
  },
  ...getFontScaling(96)
}

export const h1 = {
  extend: baseHeadingsStyles,
  fontSize: 48,
  lineHeight: 1,
  letterSpacing: '-0.01em',
  [breakpoints.up('md')]: {
    fontSize: 64
  },
  ...getFontScaling(64)
}

export const h2 = {
  extend: baseHeadingsStyles,
  fontSize: 32,
  lineHeight: 1.2,
  [breakpoints.up('md')]: {
    fontSize: 40
  },
  ...getFontScaling(40)
}

export const h3 = {
  extend: baseHeadingsStyles,
  fontSize: 24,
  lineHeight: 1.25,
  [breakpoints.up('md')]: {
    fontSize: 32
  },
  ...getFontScaling(32)
}

export const h4 = {
  extend: baseHeadingsStyles,
  fontSize: 20,
  lineHeight: 1.4,
  [breakpoints.up('md')]: {
    fontSize: 24
  },
  [breakpoints.up('xxl')]: {
    fontSize: 28
  },
  ...getFontScaling(28)
}

export const h5 = {
  fontFamily: fonts.body,
  fontWeight: 'normal',
  fontSize: 16,
  lineHeight: 1.4,
  marginBottom: '1.4em',
  [breakpoints.up('md')]: {
    fontSize: 20
  },
  ...getFontScaling(20)
}

export const bodyPlus = {
  extend: baseBodyStyles,
  fontSize: 16,
  lineHeight: 1.4,
  ...getFontScaling(16)
}

export const body = {
  extend: baseBodyStyles,
  // [breakpoints.up('xxl')]: {
  //   fontSize: 16
  // },
  ...getFontScaling(14)
}

export const bodySmall = {
  extend: baseBodyStyles,
  fontSize: 12,
  lineHeight: 1.7,
  ...getFontScaling(12)
}

export const caption = {
  extend: baseMonoStyles,
  textTransform: 'uppercase',
  fontSize: 10,
  lineHeight: 1.6,
  ...getFontScaling(10)
}

export const button = {
  extend: baseMonoStyles,
  textTransform: 'uppercase',
  fontSize: 12,
  lineHeight: 1.3,
  ...getFontScaling(12)
}

export const label = {
  extend: baseMonoStyles,
  textTransform: 'uppercase',
  fontSize: 12,
  lineHeight: 1.4,
  opacity: 0.5,
  ...getFontScaling(12)
}
