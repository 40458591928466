import React, { useMemo, useRef, useContext } from 'react'
import cn from 'clsx'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getPageBackgroundColor, getWorkHome } from '../../redux/slices/content'
// import { isCurrentBreakpointMobile } from '../../redux/slices/layout'
import Link from '../Link'
import { resolveInternalLink } from '../../helpers/resolveLink'
import theme, { span } from '../../style/theme'
import ImageVideoTicker from './ImageTicker'
import ProgressLine, { useProgressLineClickAnimation } from '../ProgressLine'
import Color from 'color'
import { useCursorHover } from '../Cursor'
import ColorContext from '../ColorContext'
import useHovering from '../../hooks/useHovering'
import composeRefs from '../../helpers/composeRefs'

const ArticleGridTile = ({ className, article, span, aspect = '1:1', largeSpan, preload, largeAspect, mobileSpan = 2, mobileAspect = '34:37' }) => {
  const {
    title,
    tag,
    image,
    hero_images: heroImages,
    summary,
    client,
    hover_label: hoverLabel,
    desktop_video: desktopVideo,
    mobile_video: mobileVideo
  } = article
  const { borderColor } = useContext(ColorContext)
  const pageBackgroundColor = useSelector(getPageBackgroundColor)
  const classes = useStyles({ span, largeSpan: largeSpan || span, mobileSpan })
  const linkRef = useRef()

  const workHome = useSelector(getWorkHome)

  const images = heroImages || [image]
  const link = useMemo(() => resolveInternalLink(article), [article])
  const aspects = useMemo(() => {
    const getAspect = (a) => {
      const split = a.split(':')
      return split[0] / split[1]
    }
    return {
      aspect: getAspect(aspect),
      largeAspect: getAspect(largeAspect || aspect),
      mobileAspect: getAspect(mobileAspect || aspect)
    }
  }, [aspect])

  const { ref: hoverRef, hovering } = useHovering(true)

  useCursorHover(linkRef, hoverLabel, false, null, null, hoverLabel)
  const { lineRef, clickRef } = useProgressLineClickAnimation()

  return (
    <div className={cn(classes.tile, className)}>
      <div ref={composeRefs(clickRef, linkRef, hoverRef)}>
        <Link className={classes.tileBody} link={link} nonLinkTag='div'>
          {(image || desktopVideo) && (
            <ImageVideoTicker
              images={images}
              className={classes.image}
              animate={hovering}
              desktopVideo={desktopVideo}
              mobileVideo={mobileVideo}
              preload={preload}
              {...aspects}
            />
          )}
          <div className={classes.content}>
            <h2 className={classes.title}>{title}</h2>
            <div className={classes.summary}>{summary}</div>
          </div>
        </Link>
        <div className={classes.meta}>
          {tag && <Link to={`/${workHome.reference.slug}?filter=${tag.slug}`} className={classes.tag}>{tag.title}</Link>}
          <span className={classes.client}>{client}</span>
        </div>
        <ProgressLine
          backgroundColor={borderColor}
          hovering={hovering}
          color={pageBackgroundColor && Color(pageBackgroundColor).isDark() ? theme.colors.white : theme.colors.black}
          ref={lineRef}
        />
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  tile: {
    width: '100%',
    display: 'block',
    position: 'relative',
    gridColumn: ({ mobileSpan = 2 }) => `span ${mobileSpan}`,
    [theme.breakpoints.up('xs')]: {
      gridColumn: ({ span = 1 }) => `span ${span}`
    },
    [theme.breakpoints.up('lg')]: {
      gridColumn: ({ largeSpan = 1 }) => `span ${largeSpan}`
    }
  },
  meta: {
    extend: theme.textStyles.caption,
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      maxWidth: span(4, 'md')
    }
  },
  client: {
    opacity: 0.5,
    width: '50%'
  },
  tag: {
    width: '50%',
    textDecoration: 'none'
  },
  tileBody: {
    textDecoration: 'none'
  },
  content: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: span(5, 'md')
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: span(3, 'md'),
      '&.layout-half-width': {
        maxWidth: span(4, 'md')
      },
      '&.layout-full-width': {
        marginLeft: 'auto',
        paddingLeft: theme.grid.gutter,
        maxWidth: span(4, 'md')
      }
    }
  },
  image: {
    marginBottom: theme.spacing(1),
    '& video': {
      objectFit: 'cover'
    }
  },
  title: {
    marginBottom: theme.spacing(1),
    extend: theme.textStyles.bodyPlus
  },
  summary: {
    extend: theme.textStyles.body,
    marginBottom: theme.spacing(3.5),
    opacity: 0.5
  }
}, { name: 'ArticleGridTile' })

export default ArticleGridTile
