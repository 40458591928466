import React, { forwardRef, useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'clsx'
import get from 'lodash/get'
import last from 'lodash/last'
import theme from '../../style/theme'
import RichText from '../RichText'
import StyledButton from '../StyledButton'
import Section from '../Section'
import { resolveLink } from '../../helpers/resolveLink'
import useFadeInAnimation from '../../animations/useFadeInAnimation'
import SplitWordsAnimation from '../SplitWordsAnimation'
import compact from 'lodash/compact'
import composeRefs from '../../helpers/composeRefs'
import toArray from 'lodash/toArray'

const RichTextSlice = forwardRef(({ className, slice, ...props }, ref) => {
  const {
    featuredText,
    featuredTextStyle = 'h4',
    link,
    text,
    no_bottom_margin: noBottomMargin,
    span
  } = slice

  const classes = useStyles({ span })

  const resolvedLink = resolveLink(get(link, [0]))
  const shiftLink = text && get(last(text), ['type']) === 'list' // If the last item in the rich text is a list then we want to move the items to be inline with the second column
  const richTextRef = useRef()

  var [inViewRef] = useFadeInAnimation(useCallback(() => {
    if (!richTextRef.current.children[0]) return []
    const richTextParagraphs = toArray(get(richTextRef.current, ['children', 0, 'children', 0, 'children'], []))
    return compact([
      ...richTextParagraphs,
      richTextRef.current.children[1]
    ])
  }, []), { stagger: 0.15 })

  return (
    <Section className={className} grid noBottomMargin={noBottomMargin}>
      <div className={classes.firstColumn}>
        {featuredText && (
          <SplitWordsAnimation duration={0.5} stagger={0.035}>
            <h2 className={cn(classes.featuredText, featuredTextStyle)}>
              {featuredText}
            </h2>
          </SplitWordsAnimation>
        )}
      </div>
      <div className={classes.secondColumn} ref={composeRefs(inViewRef, richTextRef)}>
        {text && <RichText className={classes.richTextContent} content={text} />}
        {resolvedLink && (
          <div className={cn(classes.linkWrapper, { shiftLink })}>
            <StyledButton link={resolvedLink} className={classes.link} />
          </div>
        )}
      </div>
    </Section>
  )
})

const useStyles = createUseStyles({
  col: {
  },
  firstColumn: {
    composes: '$col',
    gridRow: 1,
    gridColumn: '1 / span 2',
    [theme.breakpoints.up('xs')]: {
      marginBottom: 0,
      gridColumn: '1 / span 5'
    }
  },
  secondColumn: {
    composes: '$col',
    gridRow: 2,
    gridColumn: '1 / span 2',
    [theme.breakpoints.up('xs')]: {
      display: 'grid',
      columnGap: theme.grid.gutter,
      rowGap: '16px',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridRow: 1,
      gridColumn: ({ span = 5 }) => `${12 - span} / span ${span}`
    }
  },
  richTextContent: {
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'span 5'
    },
    '& > div > *': {
      opacity: 0
    },
    '& h6': {
      extend: theme.textStyles.caption,
      marginBottom: theme.spacing(1)
    },
    '& p': {
      marginBottom: theme.spacing(1)
    },
    '& ul': {
      margin: [theme.spacing(1), 0],
      listStyle: 'none',
      padding: 0,
      extend: theme.textStyles.caption
    },
    '& li': {
      padding: [theme.spacing(0.5), 0],
      borderBottom: `1px solid ${theme.colors.borderDark}`
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4)
    }
  },
  link: {
  },
  linkWrapper: {
    opacity: 0,
    whiteSpace: 'nowrap',
    '&.shiftLink': {
      gridColumn: '1 / span 5',
      [theme.breakpoints.up('xs')]: {
        gridColumn: '3 / span 3'
      }
    }
  },
  featuredText: {
    // For some reason there is issues when doing this using a JSS param when
    // there is more than one RichTextSlice on the page? (Hence somewhat archaic implementation)
    '&.h1': {
      extend: theme.textStyles.h1
    },
    '&.h2': {
      extend: theme.textStyles.h2
    },
    '&.h3': {
      extend: theme.textStyles.h3
    },
    '&.h4': {
      extend: theme.textStyles.h4
    }
  }
}, { name: 'RichTextSlice' })

export default RichTextSlice
